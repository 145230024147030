import React, { useCallback, useEffect, useState } from "react";
import { useAuth } from "../../contexts/UserContext";
import LogoSrc from "../../images/bb-logo.png";
import { ReactComponent as IconClose } from "../../images/icon-close.svg";
import Button from "../Button";
import IconButton from "../IconButton";
// import { ReactComponent as IconMenu } from "../../images/icon-menu.svg";
import Link from "../Link";
import Navigation from "../Navigation";
import "./Header.scss";
import { useNavigate } from "react-router-dom";
import Avatar from "../Avatar";
import { ChatBotEvent } from "../../utils/constants";

const Header = ({ className = "", logo, navigation, toggle, hideLogin }) => {
  const hideChatBotEvent = new CustomEvent(ChatBotEvent.close);
  const navigate = useNavigate();
  const { user, userToken, resetUser } = useAuth();
  const [isOpenProfile, toggleProfile] = useState(false);
  const [isOpenMenu, toggleMenu] = useState(false);
  const allowToSkip = process.env.REACT_APP_ALLOW_ANONYMOUS;

  useEffect(() => {
    toggleMenu(false);
    toggleProfile(false);
  }, [toggle, toggleMenu, toggleProfile]);

  const navigateToAccount = useCallback(() => {
    navigate("/account");
  }, [navigate]);

  return (
    <header className={`Header ${className}`}>
      <div className="Header__content">
        {/*<IconButton*/}
        {/*  className="Header__menuIcon"*/}
        {/*  icon={<IconMenu />}*/}
        {/*  onClick={toggleMenu}*/}
        {/*/>*/}
        <div
          className={"Header__logo"}
          onClick={() => {
            toggleProfile(false);
          }}
        >
          <Link to="/">
            <img src={logo ?? LogoSrc} alt="" height={38} />
          </Link>
        </div>
        {userToken ? (
          user ? (
            <div className="Header__avatarWrap">
              <IconButton
                icon={
                  <Avatar
                    firstName={user?.firstName}
                    lastName={user?.lastName}
                    className="Header__avatar"
                  />
                }
                onClick={() => {
                  document.dispatchEvent(hideChatBotEvent);
                  toggleProfile(!isOpenProfile);
                }}
              />
            </div>
          ) : null
        ) : !hideLogin && !allowToSkip ? (
          <Button
            onClick={() => {
              navigate("/sign-in");
            }}
            size="sm"
            className="Header__checkIn"
          >
            Login
          </Button>
        ) : null}
      </div>
      {userToken ? (
        <Navigation
          className={`Header__profileNav ${
            isOpenProfile ? "Header__profileNav--isOpen" : ""
          }`}
        >
          <div style={styles.buttonProfileWrap}>
            <Button id="signOut" variant="ghost" onClick={navigateToAccount}>
              <span className="lHeadline">My account</span>
            </Button>
            <Button id="signOut" variant="ghost" onClick={resetUser}>
              <span className="lHeadline">Sign out</span>
            </Button>
          </div>
        </Navigation>
      ) : null}

      <Navigation
        className={`Header__nav ${isOpenMenu ? "Header__nav--isOpen" : ""}`}
      >
        <div style={styles.buttonMenuWrap}>
          <IconButton
            className="Header__closeIcon"
            icon={<IconClose />}
            onClick={() => toggleMenu(false)}
          />
          <Button
            onClick={() => toggleMenu(false)}
            as={Link}
            variant="ghost"
            to="/"
          >
            Main Screen
          </Button>
          <Button
            onClick={() => {
              toggleMenu(false);
            }}
            as={Link}
            // href={profilerRoute + `?token=${userToken}`}
            variant="ghost"
          >
            Check-in Result
          </Button>
        </div>
      </Navigation>
    </header>
  );
};

const styles = {
  buttonMenuWrap: {
    width: 380,
    height: 1000,
    marginTop: 0,
    paddingTop: 20,
    alignSelf: "flex-start",
    paddingLeft: 25,
  },

  buttonProfileWrap: {
    width: 230,
    backgroundColor: "white",
    borderRadius: 15,
    marginTop: 5,
    paddingBottom: 10,
    border: "1px solid black",
    marginRight: "3%",
    alignSelf: "flex-end",
  },

  separator: {
    height: 1,
    width: 340,
    backgroundColor: "white",
    marginTop: 10,
    marginBottom: 10,
  },
};

export default Header;
